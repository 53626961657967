/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || 'light',
    navbarSearchAndPinList: navbarSearchAndPinList,
    AppActiveUser: {
        id: 0,
        name: '홍길동',
        tail: '기자',
        levelText: '기자',
        level: '7',
        email: '',
        department: '',
    },
    themePrimaryColor: colors.primary,
    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    
    accessToken: null,
    categories1: [],
    categories2: [],
    series: [],
    writer: [],
    writerAll: null,
    states: [],
    portals: [{'id': 0, 'name': 'Naver', 'oldName': 'mna'},
              {'id': 1, 'name': 'Daum', 'oldName': 'ndaum'},
              {'id': 2, 'name': 'ZUM', 'oldName': 'zum'},
              {'id': 3, 'name': 'Nate', 'oldName': 'nt'},
              {'id': 4, 'name': 'KU', 'oldName': 'ku'}],
    portalsWithAll: [{'id': 0, 'name': 'Naver', 'oldName': 'mna'},
            {'id': 1, 'name': 'Daum', 'oldName': 'ndaum'},
            {'id': 2, 'name': 'ZUM', 'oldName': 'zum'},
            {'id': 3, 'name': 'Nate', 'oldName': 'nt'},
            {'id': 4, 'name': 'KU', 'oldName': 'ku'},
            {'id': 9, 'name': 'ALL', 'oldName': '(none)'}],
    maxWidth: 700,
    calendarLabels: [
        { text: '데스크' ,value : '데스크', color: 'success' },
        { text: '평일당직', value: '평일당직', color: 'warning'},
        { text: '주말공휴일재택', value: '주말공재', color: 'danger'},
        { text: '주말공휴일현장', value: '주말공현', color: 'primary'},
    ],
    timeOffLabels: [
        { text: '데스크' ,value : '데스크', color: 'success' },
        // { text: '팀장', value: '팀장', color: 'warning'},
        { text: '기자 및 일반직원', value: '기자 및 일반직원', color: 'primary'},
    ],
    departmentOption: [
        { text: "미분류", value: "g0" },
        { text: "정치", value: "g1" },
        { text: "사회", value: "g10" },
        { text: "시장경제", value: "g2" },
        { text: "정책경제", value: "g6" },
        { text: "생활경제", value: "g7" },
        { text: "산업", value: "g3" },
        { text: "ICT융합", value: "g11" },
        { text: "스포츠", value: "g4" },
        { text: "연예", value: "g5" },
        { text: "사진", value: "g8" },
        { text: "뉴미디어뉴스팀", value: "g9" },
    ],
    levelOption: [
        { text: "최종관리자", value: "11" },
        { text: "관리자", value: "10" },
        { text: "데스크", value: "9" },
        { text: "일반기자", value: "8" },
        { text: "외부기자", value: "3" }, // test
    ],
}

export default state
