/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import moment from 'moment';
const mutations = {
    
    LOGIN (state, accessToken) {
        state.accessToken = accessToken
        localStorage.accessToken = accessToken
    },
    bannerManagerLOGIN (state, bannertoken) {
        state.bannertoken = bannertoken
        localStorage.bannertoken = bannertoken
    },
    LOGOUT (state) {
        state.accessToken = null;
        delete localStorage.accessToken;
        state.bannertoken = null;
        delete localStorage.bannertoken;
        state.AppActiveUser.id = '';
        state.AppActiveUser.name = '';
        state.AppActiveUser.tail = '';
        state.AppActiveUser.level = '';
        state.AppActiveUser.email = '';
        state.AppActiveUser.levelText = '';
    },
    bannerManagerUSERSTATE(state, user){
        state.AppActiveUser.id = user[0].id;
        state.AppActiveUser.name = user[0].name;
        state.AppActiveUser.level = 1;
        state.AppActiveUser.levelText = '광고관리';
    },
    USERSTATE (state, user) {    
        // console.log(user)    
        state.AppActiveUser.id = user[0][0].idx;
        state.AppActiveUser.name = user[0][0].name;
        state.AppActiveUser.tail = user[0][0].tail;
        state.AppActiveUser.level = user[0][0].level;
        state.AppActiveUser.email = user[0][0].email;
        state.AppActiveUser.department = user[0][0].option1;
        state.AppActiveUser.permission = user[0][0].permission ? JSON.parse(user[0][0].permission) : {};
        
        var stateText;
        // if(user[0][0].level == 1) stateText = '광고관리자';

        /* if(user[0][0].level == 2) stateText = '넷포터';
        else if(user[0][0].level == 3) stateText = '객원기자';
        else */ if(user[0][0].level == 3) stateText = '외부기자';

        // else if(user[0][0].level == 7) stateText = '평기자';

        else if(user[0][0].level == 7 || user[0][0].level == 8) stateText = '일반기자';

        else if(user[0][0].level == 9) stateText = '데스크';
        else if(user[0][0].level == 10) stateText = '관리자';
        state.AppActiveUser.levelText = stateText;
        state.categories1 = user[1];
        state.categories2 = user[2];
        state.states = user[3];
        state.writer = user[4];
        state.series = user[5];
    },
    SetWriterAll (state, writerAll){
        state.writerAll = writerAll;
    },
    EditWriterList (state, writer){
        let tmpIdx = state.writer.findIndex(e => e.idx == writer.idx);
        if(tmpIdx > -1){
            state.writer[tmpIdx] = {
                idx: writer.idx,
                nickname: writer.nickname,
                level: writer.level,
                email: writer.email,
                entry_date: writer.entryDate
            }
        }else{
            state.writer.push({
                idx: writer.idx,
                nickname: writer.nickname,
                level: writer.level,
                email: writer.email,
                entry_date: writer.entryDate
            })
        }
        state.writer = state.writer;
        if(state.writerAll){
            tmpIdx = state.writerAll.findIndex(e => e.idx == writer.idx);
            let resetDate = moment(writer.entryDate).year(moment().format('YYYY'))
            if(moment() > resetDate){
            resetDate = resetDate.format('YYYY-MM-DD')
            }else{
            resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
            }
            if(tmpIdx > -1){
                state.writerAll[tmpIdx] = {
                    idx: writer.idx,
                    name: writer.name,
                    level: writer.level,
                    email: writer.email,
                    tail: writer.tail,
                    entry_date: writer.entryDate,
                    max_timeoff: writer.maxTimeoff,
                    resetDate,
                }
            }else{
                state.writerAll.push({
                    idx: writer.idx,
                    name: writer.name,
                    level: writer.level,
                    email: writer.email,
                    tail: writer.tail,
                    entry_date: writer.entryDate,
                    max_timeoff: writer.maxTimeoff,
                    resetDate,
                })
            }
            state.writerAll = state.writerAll;
        }
        
    },
    SEREIESEDIT (state, seriesArray) {
        state.series = seriesArray;
    },
    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    UPDATE_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
        state.sidebarItemsMin = val;
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val;
    },
    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val;
    },
    TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    UPDATE_THEME(state, val) {
        state.theme = val;
    },
    UPDATE_WINDOW_BREAKPOINT(state, val) {
        state.breakpoint = val;
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val;
    },
    UPDATE_STATUS_CHAT(state, value) {
        state.AppActiveUser.status = value;
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
        // update the main list
        state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

        // if val is true add it to starred else remove
        if(payload.val) {
            state.starredPages.push(state.navbarSearchAndPinList.data[index])
        }else {
            // find item index from starred pages
            const index = state.starredPages.findIndex((item) => item.index == payload.index)
            // remove item using index
            state.starredPages.splice(index, 1);
        }
    },

    // The Navbar
    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10);
        state.starredPages = list.concat(starredPagesMore);
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10];
        const starredPagesLimited = state.starredPages.slice(0, 10);
        state.starredPages = starredPagesLimited.concat(list);

        state.starredPages.slice(0,10).map((i) => {
            if(list.indexOf(i) > -1) downToUp = true
        })
        if(!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited);
        }
    },
}

export default mutations
